import { ApolloProvider } from '@apollo/client';
import { RemixBrowser } from '@remix-run/react';
import { Hub } from 'aws-amplify/utils';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { amplifyConfigure } from './amplify-configure.ts';
import { apolloClient } from './apollo/apollo-client.ts';
import { handleImportError } from './handle-import-error.ts';
import { invalidateSession } from './routes-utils/handle-authorized-data.ts';
import { initSentry } from './sentry.client.ts';
import { traceError } from '@/utils/trace-error.ts';

amplifyConfigure();

if (__WITH_SENTRY__) {
  initSentry();
}

if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker
    .register('/sw.js', { scope: '/' })
    .catch((err) =>
      traceError(
        new Error('Service Worker registration failed', { cause: err }),
      ),
    );
}

Hub.listen('auth', async ({ payload }) => {
  invalidateSession();

  if (payload.event === 'signedOut') {
    await apolloClient.clearStore();
  }
});

handleImportError();

startTransition(() => {
  hydrateRoot(
    document,
    <ApolloProvider client={apolloClient}>
      <RemixBrowser />
    </ApolloProvider>,
  );
});

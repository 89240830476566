import { useLocation, useMatches } from '@remix-run/react';
import { browserTracingIntegration, init } from '@sentry/remix';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { useEffect } from 'react';

export const initSentry = () => {
  init({
    dsn: 'https://bea29d59e21c0a1bdf6569c6499f7e9a@o4505956381687808.ingest.us.sentry.io/4507698594512896',
    tracesSampleRate: 0.1,
    environment: import.meta.env.MODE,
    integrations: [
      browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
    beforeBreadcrumb: excludeGraphQLFetch,
  });
};

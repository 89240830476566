import { traceError } from './utils/trace-error';

const maxRetries = 3;

const storageKey = 'import-error-retries';

export const handleImportError = () => {
  window.addEventListener('vite:preloadError', () => {
    const errRetries: string | null = sessionStorage.getItem(storageKey);

    if (typeof errRetries === 'string') {
      const retries = parseInt(errRetries);

      if (retries >= maxRetries) {
        traceError(new Error('Error during page load'));
        return;
      }

      sessionStorage.setItem(storageKey, String(retries + 1));
    } else {
      sessionStorage.setItem(storageKey, '1');
    }

    console.warn('Error during module load. Reloading...');
    window.location.reload();
  });
};
